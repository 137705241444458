// app/javascript/controllers/tab_controller.js

import { Controller } from '@hotwired/stimulus';
import { Tab } from 'bootstrap'; // Import Bootstrap's Tab component

export default class extends Controller {
  static targets = ['tab'];

  connect() {
    this.currentIndex = 0;
    this.startTabCycle();
  }

  disconnect() {
    this.stopTabCycle();
  }

  startTabCycle() {
    this.stopTabCycle(); // Ensure any existing interval is cleared
    this.tabCycle = setInterval(() => this.nextTab(), 10000);
  }

  stopTabCycle() {
    if (this.tabCycle) {
      clearInterval(this.tabCycle);
      this.tabCycle = null; // Set to null for clarity
    }
  }

  nextTab() {
    this.currentIndex = (this.currentIndex + 1) % this.tabTargets.length;
    this.showTab(this.currentIndex);
  }

  showTab(index) {
    this.currentIndex = index; // Update currentIndex to the new index
    this.tabTargets.forEach((tab, i) => {
      if (i !== index) return;

      const tabElement = tab;
      const bootstrapTab = new Tab(tabElement);
      bootstrapTab.show(); // Use Bootstrap's method to show the tab
    });
  }

  tabClicked(event) {
    event.preventDefault();
    this.stopTabCycle();
    const clickedIndex = this.tabTargets.indexOf(event.currentTarget);
    this.showTab(clickedIndex);

    setTimeout(() => {
      this.startTabCycle();
    }, 15000);
  }
}

