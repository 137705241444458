// app/javascript/controllers/scroll_snap_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];

  initialize() {
    this.currentIndex = 0;
  }

  scrollToItem(index) {
    if (index < 0 || index >= this.itemTargets.length) return;  

    this.currentIndex = index;
    this.itemTargets[this.currentIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }

  previous() {
    this.scrollToItem(this.currentIndex - 1);
  }

  next() {
    this.scrollToItem(this.currentIndex + 1);
  }
}
